import { Button } from '@dehaat/dds';
import fetchAllBrands from '@dehaat/kisan-app-bl/api/fetchAllBrands';
import getFeedOrder from '@dehaat/kisan-app-bl/api/getFeedOrder';
import { DEFAULT_LANG, StringBoolean } from '@dehaat/kisan-app-bl/constants/common';
import { FeedOrder } from '@dehaat/kisan-app-bl/models/Feed';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useContext, useEffect, useState } from 'react';
import FloatingHelp from '@/components/askQuestion/FloatingHelp';
import BottomNavBar from '@/components/BottomNavBar';
import CheckoutPopup from '@/components/CheckoutPopup';
import Footer, { FooterData } from '@/components/Footer';
import FeedManager from '@/components/home/FeedManager';
import OfferStrip from '@/components/homeV2/offerStrip';
import MainHeader from '@/components/MainHeader';
import NearByDCList from '@/components/NearByDCList';
import PostManager from '@/components/posts/PostManager';
import BrandImageName from '@/components/products/BrandImageName';
import ProductCardLoading from '@/components/products/ProductCardLoading';
import ProductResults from '@/components/products/ProductResults';
import ProtectCropBanner from '@/components/ProtectCropBanner';
import SearchBox from '@/components/SearchBox';
import SocialMetaTags from '@/components/SocialMetaTags';
import { ACCESS_TOKEN_KEY, DeviceType, FeedTabs, HOME_PAGE_TITLE, LAT_LNG_COOKIE_NAME, NAVIGATION_FROM_APP_CODE, PRODUCTS_QUERY_OPEN, RECENT_PRODUCT_SEARCHES_COUNT, RECENT_PRODUCT_SEARCHES_KEY, VENDOR_ID_COOKIE_NAME, WEBSITE_URL } from '@/constants/common';
import { ENTITY_TYPES } from '@/constants/home';
import { CartContext } from '@/context/CartProvider';
import { useAppSelector } from '@/hooks/reduxHooks';
import useDebounce from '@/hooks/useDebounce';
import useSearchProductsV2 from '@/hooks/useSearchProductsV2';
import useTriggerEvents from '@/hooks/useTriggerEvents';
import ApiResponse from '@/models/ApiResponse';
import { Brand, Product, Product2, ProductsQueryType } from '@/models/Product';
import { selectCurrentVendor, selectCurrentVendorId, selectLatLng, selectLoadingHyperlocalData } from '@/store/features/locationSlice';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { event as GAEvent, trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import getHomePageData, { HeaderData } from '@/utils/api/getHomePageData';
import { get } from '@/utils/axios';
import { isCartEmpty } from '@/utils/cart';
import { getCookieValue, getDeviceType, getLocalStorageKey, isDigiAcreUser, isFromDFA, setLocalStorageKey } from '@/utils/helper';
import transformProductInventorySet from '@/utils/helpers/transformProductInventorySet';
interface Props {
  feedList: FeedOrder;
  fromApp: boolean;
  headerData: HeaderData | null;
  deviceType: DeviceType;
  footerData: FooterData | null;
}
const HomePage = ({
  feedList,
  fromApp,
  headerData,
  deviceType,
  footerData
}: Props) => {
  const router = useRouter();
  const {
    cartItems
  } = useContext(CartContext);
  const currentVendorId = useAppSelector(selectCurrentVendorId);
  const latLng = useAppSelector(selectLatLng) || undefined;
  const isHyperlocalDataLoading = useAppSelector(selectLoadingHyperlocalData);
  const vendorDetails = useAppSelector(selectCurrentVendor);
  const {
    query,
    locale
  } = router;
  const {
    so: openSearch
  }: ProductsQueryType = query;
  // tab :- 0 - home, 1 - community, 2 - shop
  // hb :- hide bottom navigation if value is t
  const {
    tab,
    redirect_uri,
    hb
  } = query;
  const {
    infiniteScrollRef,
    searchedProducts,
    searchingProducts,
    searchQuery
  } = useSearchProductsV2();
  const [searchFocused, setSearchFocused] = useState(Boolean(searchQuery) || openSearch === PRODUCTS_QUERY_OPEN);
  const [isTyped, setIsTyped] = useState(false);
  const [hasScrolledTo500px, setHasScrolledTo500px] = useState(false);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [popularProducts, setPopularProducts] = useState<Product[]>([]);
  const [isPopularProductLoading, setPopularProductLoading] = useState(false);
  const {
    trackCustomEvent: trackCustomEventUsingHook
  } = useTriggerEvents();
  const {
    t
  } = useTranslation('main');
  const hideBottomNavigation = searchFocused || fromApp || hb === 't';
  const fromDFA = isFromDFA(getCookieValue(NAVIGATION_FROM_APP_CODE));
  const isMobile = deviceType == DeviceType.MOBILE;
  useEffect(() => {
    const recentSearchValue = (getLocalStorageKey(RECENT_PRODUCT_SEARCHES_KEY) as Array<string>) || [];
    setRecentSearches(recentSearchValue);
  }, []);
  const updateRecentSearches = (recentSearch: string) => {
    setRecentSearches(prevRecentSearches => {
      const index = prevRecentSearches.findIndex(item => item === recentSearch);
      const restOfArray = index === -1 ? prevRecentSearches.slice(0, RECENT_PRODUCT_SEARCHES_COUNT - 1) : prevRecentSearches.filter((_, recentIndex) => recentIndex !== index);
      const newRecentSearches = [recentSearch, ...restOfArray];
      setLocalStorageKey(RECENT_PRODUCT_SEARCHES_KEY, newRecentSearches);
      return newRecentSearches;
    });
  };
  const debouncedSearch = useDebounce((newSearchQuery: string) => {
    if (searchQuery !== newSearchQuery) {
      if (newSearchQuery) {
        GAEvent('search', {
          search_term: newSearchQuery,
          method: isTyped ? 'TypedSearch' : 'VoiceSearch'
        });
        const searchProductName = searchedProducts.map(item => item.name);
        const eventParam = {
          SearchKey: newSearchQuery,
          First10Results: searchProductName.toString(),
          SearchType: isTyped ? 'TypedSearch' : 'VoiceSearch'
        };
        trackCustomEventUsingHook(CustomEventTypes.PRODUCT_SEARCH_INITIATED, JSON.stringify(eventParam));
        router.replace({
          pathname: '/',
          query: {
            ...query,
            s: encodeURIComponent(newSearchQuery)
          }
        }, undefined, {
          shallow: true
        });
        updateRecentSearches(newSearchQuery);
      } else {
        router.replace({
          pathname: '/',
          query: {}
        }, undefined, {
          shallow: true
        });
      }
      setIsTyped(false);
    }
  }, 500);
  const handleTabClick = (feedTab: number) => {
    router.replace({
      pathname: '/',
      query: {
        tab: feedTab
      }
    }, undefined, {
      shallow: true,
      scroll: true
    });
  };
  const fetchAllBrand = useCallback(async () => {
    const brands = await fetchAllBrands(locale, latLng, currentVendorId);
    setBrands(brands);
    // LatLng and vendorId are tightly coupled values, so it's better to include currentVendorId only in deps[]
    // since currentVendorId changes immediately after latlng changes
    // This avoids fetching same data twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVendorId, locale]);
  const fetchPopularProduct = useCallback(async () => {
    try {
      setPopularProductLoading(true);
      const params = {
        nearby: currentVendorId ? StringBoolean.TRUE : StringBoolean.FALSE,
        getVariants: StringBoolean.TRUE,
        page_size: 9,
        lang: locale || DEFAULT_LANG,
        vendor_id: currentVendorId
      };
      const response = await get<ApiResponse<Product2>>(`/hyperlocal/v1/products`, params, undefined);
      const products = response.results.map((product: Product2) => transformProductInventorySet(product));
      setPopularProducts(products);
    } catch {} finally {
      setPopularProductLoading(false);
    }
  }, [currentVendorId, locale]);
  useEffect(() => {
    fetchAllBrand();
    fetchPopularProduct();
  }, [fetchAllBrand, fetchPopularProduct]);
  useEffect(() => {
    const handleMutation = (mutationsList: MutationRecord[], observer: MutationObserver) => {
      for (let i = 0; i < mutationsList.length; i++) {
        const mutation = mutationsList[i];
        if (mutation.type === 'childList') {
          // Check if the added nodes include a script tag with the specific src attribute
          const addedNodes = mutation.addedNodes;
          for (let j = 0; j < addedNodes.length; j++) {
            const node = addedNodes[j];
            if (node instanceof HTMLScriptElement && node.src.includes('https://dap.agrevolution.in/adserver/www/delivery/al.php')) {
              const adContainer = node.parentElement;
              if (adContainer) {
                adContainer.style.display = 'block';
              }

              // Disconnect the observer once the script tag is found
              observer.disconnect();
            }
          }
        }
      }
    };
    const observer = new MutationObserver(handleMutation);

    // Configure and start the observer to watch for changes in the body
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      if (scrollY >= 500 && !hasScrolledTo500px) {
        // The user has scrolled to 500px
        trackCustomEvent(CustomEventTypes.HOME_PAGE_SCROLLED_TO_500PX);
        window.removeEventListener('scroll', handleScroll);
        // Set the state to true to ensure the event fires only once
        setHasScrolledTo500px(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolledTo500px]);
  useEffect(() => {
    if (currentVendorId) {
      GAEvent('is_hyperlocal_area', {});
    }
  }, [currentVendorId]);
  useEffect(() => {
    const eventParam = {
      VendorName: vendorDetails?.company_name,
      VendorId: currentVendorId
    };
    trackCustomEventUsingHook(CustomEventTypes.USER_LANDED_ON_COMMERCE_PLATFORM_HOME, JSON.stringify(eventParam));
  }, [vendorDetails]);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <meta name="title" content={!fromApp ? t('common:dehaat_shop') : HOME_PAGE_TITLE} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <meta name="description" content={t('common:app_description')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <link rel="canonical" href={`${WEBSITE_URL}${locale === DEFAULT_LANG ? '' : `/${locale}`}`} />
        <link rel="alternate" hrefLang={`${locale}-in`} href={WEBSITE_URL} />
      </Head>
      <SocialMetaTags title={HOME_PAGE_TITLE} description={t('common:app_description')} data-sentry-element="SocialMetaTags" data-sentry-source-file="index.tsx" />
      <main className="relative flex flex-col bg-white min-h-screen">
        {headerData?.banner && <OfferStrip imageUrls={headerData?.banner} deviceType={deviceType} />}
        {!fromDFA && <MainHeader showCart />}
        <section className={`bg-gray-10 grow max-w-[1728px] min-h-screen m-auto w-full px-0 lg:px-10 xl:px-16 ${isCartEmpty(cartItems) ? 'pb-16' : 'pb-32'}`} data-testid={`${currentVendorId ? '' : 'non-'}hyperlocal-homepage`}>
          {Number(tab) !== FeedTabs.COMMUNITY && <div className="top-0 z-20 sticky bg-gray-10 m-auto px-4 py-2">
              <SearchBox initialSearchText={searchQuery} placeholder={t('search_placeholder')} onSearch={debouncedSearch} lang={locale || DEFAULT_LANG} onBackClick={() => {
            trackCustomEvent(CustomEventTypes.APP_BACK_BUTTON);
            if (redirect_uri) {
              router.push((redirect_uri as string));
            } else {
              router.replace(`/?tab=${tab}`, undefined, {
                shallow: true
              });
            }
            setSearchFocused(false);
          }} onFocus={() => {
            window.scrollTo(0, 0);
            setSearchFocused(true);
          }} showBackBtn={searchFocused} setIsTyped={setIsTyped} recentSearches={!searchQuery && searchFocused && !searchingProducts && searchedProducts?.length == 0 ? recentSearches : []} />
            </div>}

          {!searchFocused ? Number(tab) === FeedTabs.COMMUNITY ? <PostManager /> : <FeedManager feedList={Number(tab) === FeedTabs.SHOP ? currentVendorId ? feedList.HYP || [] : feedList.NON_HYP_WITHOUT_DC || [] : feedList.DISCOVER || []} locale={router.locale} /> : null}

          {searchFocused && <>
              {searchedProducts.length > 0 ? <div>
                  <h2 className="mx-4 mb-4 font-medium text-2xl">
                    {t('products:products')}
                  </h2>
                  <ProductResults products={searchedProducts} loading={searchingProducts} cartEmpty={false} nextLoaderRef={infiniteScrollRef} listType={ProductListTypeEnum.SEARCH} pageType="search" />
                </div> : !searchingProducts && !searchQuery && <section className="mt-3">
                    {popularProducts.length > 0 && <div>
                        <h2 className="mx-4 mb-4 font-medium text-2xl">
                          {t('products:popular_products')}
                        </h2>
                        <ProductResults products={popularProducts} loading={isPopularProductLoading} cartEmpty={false} listType={ProductListTypeEnum.SEARCH} pageType="search" />
                      </div>}
                    {!isDigiAcreUser() ? brands.length > 0 && <div>
                            <h2 className="mx-4 mb-4 font-medium text-2xl">
                              {t('popular_brands')}
                            </h2>
                            <div className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-7 gap-6`}>
                              {brands.slice(0, 12)?.map(brand => <div key={brand.id}>
                                  <BrandImageName brand={brand} className="m-auto" />
                                </div>)}
                            </div>
                          </div> : null}
                  </section>}
            </>}
          {!searchingProducts && searchFocused && searchQuery && searchedProducts.length === 0 && <div className="px-4 py-2 text-center">
                {t('no_searched_products')}
              </div>}
          {searchingProducts && searchedProducts.length == 0 && <div className="gap-0 grid grid-cols-2 lg:grid-cols-3">
              <ProductCardLoading count={isMobile ? 9 : 18} />
            </div>}
          {!searchFocused && Number(tab) === FeedTabs.HOME ? <ProtectCropBanner /> : null}
          {!searchFocused && !currentVendorId && !isHyperlocalDataLoading && Number(tab) === FeedTabs.SHOP && <>
                <div className="mt-4 px-4">
                  <NearByDCList />
                </div>
                <div className="bg-neutral-10 mt-4 w-full h-2"></div>
              </>}
          {Number(tab) === FeedTabs.COMMUNITY && <Button buttonStyle={{
          margin: 16
        }} text="Read More" onClick={() => router.push('/blogs')} />}
          {isCartEmpty(cartItems) && <FloatingHelp />}
          <CheckoutPopup className={isCartEmpty(cartItems) || hideBottomNavigation ? 'bottom-0' : 'bottom-16'} data-sentry-element="CheckoutPopup" data-sentry-source-file="index.tsx" />
        </section>

        {!hideBottomNavigation && <BottomNavBar activeTab={Number(tab || FeedTabs.HOME)} handleTabClick={tab => {
        handleTabClick(tab);
        trackCustomEvent(CustomEventTypes.BOTTOM_NAV_BAR_CLICKED, JSON.stringify({
          tabName: tab
        }));
      }} />}
        <Footer footerData={footerData} data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
      </main>
    </>;
};
export const getServerSideProps: GetServerSideProps<Props> = async ({
  req,
  locale
}) => {
  const cookies = req.cookies;
  const accessToken = cookies[ACCESS_TOKEN_KEY];
  const latLong = cookies[LAT_LNG_COOKIE_NAME];
  const vendorId = cookies[VENDOR_ID_COOKIE_NAME];
  const fromApp = Boolean(cookies[NAVIGATION_FROM_APP_CODE]);
  let feedList: FeedOrder = {
    DISCOVER: [],
    HYP: [],
    NON_HYP_WITHOUT_DC: []
  };
  const isHyperLocal = vendorId && vendorId !== 'null';
  const feedsResponse = await getFeedOrder(['DISCOVER', 'HYP', 'NON_HYP_WITHOUT_DC'], isHyperLocal ? vendorId : undefined, latLong, accessToken);
  const headerFooterData = await getHomePageData(ENTITY_TYPES.HEADER_FOOTER, locale);
  const deviceType = getDeviceType(req.headers['user-agent']);
  if (feedsResponse) {
    feedList = feedsResponse;
  }
  return {
    props: {
      fromApp,
      feedList,
      title: !fromApp ? 'Dehaat Shop' : HOME_PAGE_TITLE,
      headerData: headerFooterData?.header || null,
      deviceType,
      footerData: headerFooterData?.footer || null
    }
  };
};
export default HomePage;